export const DistrictCategory = [
  { key: '0', text: '福田' },
  { key: '1', text: '罗湖' },
  { key: '2', text: '南山' },
  { key: '3', text: '宝安' },
  { key: '4', text: '盐田' },
  { key: '5', text: '龙华' },
  { key: '6', text: '光明' },
  { key: '7', text: '龙岗' },
  { key: '8', text: '坪山' },
  { key: '9', text: '大鹏' },
  { key: '10', text: '前海' },
];

export const Test = 'test';
