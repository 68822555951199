import React from 'react';
import WordsContent from './content';
// eslint-disable-next-line
function Words() {
  return (
    <WordsContent />
  );
}

export default Words;
