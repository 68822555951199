import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetProblems, SaveResults } from '../../../api/request';
import {
  IORanges,
} from '../const';

const initialState = {
  Criterias: [],
  Questions: [],
  ShowResult: false,
  ShowAnswer: false,
  Score: 0,
  QuestionId: '',
};

let checkedCount = 0;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loadAsync = createAsyncThunk(
  'criteria/GetProblems',
  async (criterias) => {
    const response = await GetProblems(criterias);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);
let displayCorrect = true;
export const criteriaSlice = createSlice({
  name: 'criteria',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addCriteria(state, action) {
      state.Criterias.push(action.payload);
    },
    addCriteriaBatch(state, action) {
      const criteria = action.payload;
      console.log(criteria);

      const criterias = state.Criterias;
      criteria.Categories.forEach((cat) => {
        criteria.Kind.forEach((kind) => {
          criteria.Types.forEach((type) => {
            const ct = {
              Min: IORanges.find((x) => x.value === criteria.InputRange[0]).val,
              Max: IORanges.find((x) => x.value === criteria.InputRange[1]).val,
              Quantity: criteria.Quantity,
              Range: {
                Min: IORanges.find((x) => x.value === criteria.OutputRange[0]).val,
                Max: IORanges.find((x) => x.value === criteria.OutputRange[1]).val,
              },
              Category: cat,
              Kind: kind,
              Type: type,
            };

            criterias.push(ct);
          });
        });
      });

      state.Criterias = criterias;
    },

    clearAll(state) {
      state.Criterias = [];
      state.Questions = [];
      state.ShowResult = false;
      state.ShowAnswer = false;
      state.Score = 0;
      state.QuestionId = '';
    },

    updateShowResult(state) {
      checkedCount += 1;
      state.ShowResult = !state.ShowResult;
    },

    updateShowAnswer(state) {
      state.ShowAnswer = !state.ShowAnswer;
    },

    updateAnswer(state, action) {
      const { index, answer } = action.payload;
      state.Questions[index].UserAnswer = answer;
    },

    submitResult(state) {
      const correct = state.Questions.filter((x) => x.Answer === x.UserAnswer).length;
      const score = correct / state.Questions.length;
      state.Score = score.toFixed(2) * 100;

      console.log(checkedCount);
      if (score === 0) {
        return;
      }

      displayCorrect = !displayCorrect;
      state.Questions.filter((item) => item.Answer === item.UserAnswer).forEach((item) => {
        item.Display = displayCorrect;
      });

      const results = {
        Results: state.Questions.map((x, i) => ({
          Index: i + 1,
          Question: x.Question,
          Answer: x.Answer.toString(),
          Category: x.Category,
          Kind: x.Kind,
          Type: x.Type,
          UserAnswer: x.UserAnswer === undefined ? '' : x.UserAnswer.toString(),
        })),
        Score: state.Score,
        QuestionId: state.QuestionId,
      };

      // save to database
      SaveResults(results);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadAsync.pending, (state) => {
        state.status = 'loading';
        state.Questions = [];
        state.ShowResult = false;
        state.ShowAnswer = false;
        state.Score = 0;
        state.QuestionId = '';
      })
      .addCase(loadAsync.fulfilled, (state, action) => {
        const questions = action.payload.Questions;
        state.status = 'idle';
        state.QuestionId = action.payload.QuestionId;
        state.Questions = questions;
      });
  },
});

export const {
  clearAll, addCriteria, updateShowResult, updateShowAnswer,
  submitResult, updateAnswer, addCriteriaBatch,
} = criteriaSlice.actions;
export const currentCriterias = (state) => state.criteria.Criterias;
export const currentQuestions = (state) => state.criteria.Questions;
export const currentShowResult = (state) => state.criteria.ShowResult;
export const currentShowAnswer = (state) => state.criteria.ShowAnswer;
export const currentScore = (state) => state.criteria.Score;

export default criteriaSlice.reducer;
