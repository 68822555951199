import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SearchZdj } from '../../api/request';

// Distrct/Street/Community/MinPrice/MaxPrice/Version/SortKey/Page/Size
const initialState = {
  Criteria: {
    Districts: [],
    Keywords: '',

    MinPrice: 0,
    MaxPrice: 0,

    SortKey: '', // sort by price

    Page: 1,
    Size: 10,
  },
  ZdjItems: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loadAsync = createAsyncThunk(
  'zdj/Search',
  async (criteria) => {
    const response = await SearchZdj(criteria);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const zdjSlice = createSlice({
  name: 'zdj',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    saveCriteria(state, action) {
      state.ZdjItems = [];
      state.Criteria = action.payload;
    },
    loadMore(state) {
      state.status = 'ready';
      state.Criteria.Page += 1;
    },
    clearAll(state) {
      if (JSON.stringify(state.Criteria) === JSON.stringify(initialState.Criteria)) {
        return;
      }
      state.Criteria = initialState.Criteria;
      state.ZdjItems = [];
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadAsync.fulfilled, (state, action) => {
        const items = action.payload;
        state.status = 'idle';
        const results = state.ZdjItems;
        items.forEach((item) => {
          if (!results.some((r) => r.Id === item.Id)) {
            results.push(item);
          }
        });
        state.ZdjItems = results;
      });
  },
});

export const { saveCriteria, clearAll, loadMore } = zdjSlice.actions;
export const currentCriteria = (state) => state.zdj.Criteria;
export const currentItems = (state) => state.zdj.ZdjItems;
export const loadingStatus = (state) => state.zdj.status;

export default zdjSlice.reducer;
