import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  SearchMemo, DeleteMemo, AddMemo,
  GetLunarDate,
} from '../../api/request';

// Distrct/Street/Community/MinPrice/MaxPrice/Version/SortKey/Page/Size
const initialState = {
  Criteria: {
    StartDate: '2022-01-01',
    EndDate: '2022-12-31',
  },

  Page: 1,
  Size: 10,

  MemoItems: [],
  DisplayedItems: [],

  LunarDate: {
    Lunar: '',
    LunarYMD: 20000101,
  },
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loadAsync = createAsyncThunk(
  'memo/Search',
  async (criteria) => {
    const response = await SearchMemo(criteria);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const deleteAsync = createAsyncThunk(
  'memo/Delete',
  async (data) => {
    await DeleteMemo(data);
    // The value we return becomes the `fulfilled` action payload
    return data;
  },
);

export const toLunarAsync = createAsyncThunk(
  'memo/Lunar',
  async (data) => {
    const response = await GetLunarDate(data);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const addAsync = createAsyncThunk(
  'memo/Add',
  async (data) => {
    await AddMemo(data);
    // The value we return becomes the `fulfilled` action payload
    return data;
  },
);

export const memoSlice = createSlice({
  name: 'memo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    saveCriteria(state, action) {
      state.MemoItems = [];
      state.Criteria = action.payload;
    },
    loadMore(state) {
      state.status = 'ready';
      state.Page += 1;
    },
    refreshData(state) {
      state.DisplayedItems = state.MemoItems.slice(0, state.Size * state.Page);
      state.status = 'idle';
    },
    clearAll(state) {
      state.Criteria = initialState.Criteria;
      state.Page = initialState.Page;
      state.Size = initialState.Size;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadAsync.fulfilled, (state, action) => {
        const items = action.payload;
        state.status = 'idle';
        state.MemoItems = items;
      })
      .addCase(deleteAsync.pending, (state) => {
        state.status = 'deleting';
      })
      .addCase(deleteAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.MemoItems = state.MemoItems.filter((x) => x.Id !== action.payload.id);
      })
      .addCase(toLunarAsync.pending, (state) => {
        state.status = 'checking';
      })
      .addCase(toLunarAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.LunarDate = action.payload;
      })
      .addCase(addAsync.pending, (state) => {
        state.status = 'adding';
      })
      .addCase(addAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log(action.payload);
      });
  },
});

export const {
  saveCriteria,
  clearAll,
  loadMore,
  refreshData,
} = memoSlice.actions;
export const currentCriteria = (state) => state.memo.Criteria;

export const currentItems = (state) => state.memo.MemoItems;
export const currentPage = (state) => state.memo.Page;
export const currentSize = (state) => state.memo.Size;
export const currentLunarDate = (state) => state.memo.LunarDate;

export const currentDisplayItems = (state) => state.memo.DisplayedItems;
export const loadingStatus = (state) => state.memo.status;

export default memoSlice.reducer;
