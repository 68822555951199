import React from 'react';
import SearchBar from './searchBar';
import ZhuyinContent from './content';
// eslint-disable-next-line
function Zhuyin() {
  return (
    <>
      <SearchBar />
      <ZhuyinContent />
    </>
  );
}

export default Zhuyin;
