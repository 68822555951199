import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwt from 'jwt-decode';
import { LoginGithubUser, LoginGoogleUser } from '../../api/request';

const initialState = {
  Status: 'initial',
  isAuthenticated: false,
  User: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loginAsync = createAsyncThunk(
  'auth/Login',
  async (params) => {
    let response;
    if (params.type === 'github') {
      response = await LoginGithubUser(params);
    }

    if (params.type === 'google') {
      response = await LoginGoogleUser(params);
    }

    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout(state) {
      state.isAuthenticated = false;
      state.User = {};
      localStorage.removeItem('token');
    },
    reloadLogin(state) {
      if (state.isAuthenticated) {
        return;
      }

      const token = localStorage.getItem('token');
      if (token === undefined || token === null) {
        return;
      }

      const decoded = jwt(token);
      const dateNow = new Date();
      if (decoded.exp < dateNow.getTime() / 1000) {
        return;
      }

      state.User = { Email: decoded.email, UserId: decoded.userId };
      state.isAuthenticated = true;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.Status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.Status = 'idle';
        const { token } = action.payload;
        const decoded = jwt(token);
        state.User = { Email: decoded.email, UserId: decoded.userId };
        state.isAuthenticated = true;
        localStorage.setItem('token', token);
      });
  },
});

export const { logout, reloadLogin } = authSlice.actions;
export const currentLoginStatus = (state) => state.auth.isAuthenticated;
export const currentUser = (state) => state.auth.User;
export const currentStatus = (state) => state.auth.Status;

export default authSlice.reducer;
